import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { IconButton, ListItemIcon } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import BusinessIcon from "@mui/icons-material/Business";
import GroupIcon from "@mui/icons-material/Group";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import theme_principal from "../themes";
import { useUser } from "../userContext"; // Importa el contexto de usuario

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window, signOut } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { user } = useUser(); // Obtiene el usuario del contexto

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isTipoD = user && user.tipo === "D"; // Verifica si el usuario es de tipo "D"

  const drawer = (
    <div
      style={{
        backgroundColor: theme_principal.palette.primary.main,
        color: "white",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Toolbar />
      <List>
        {[
          { text: "Inicio", icon: <HomeIcon />, link: "/" },
          { text: "Empresas", icon: <BusinessIcon />, link: "/empresas" },
          // Mostrar solo si no es tipo "D"
          !isTipoD && {
            text: "Distribuidores",
            icon: <GroupIcon />,
            link: "/distribuidores",
          },
          {
            text: "Códigos promocionales",
            icon: <LocalOfferIcon />,
            link: "/codigos-promocionales",
          },
          // Mostrar solo si no es tipo "D"
          !isTipoD && {
            text: "Crear distribuidor",
            icon: <PersonAddIcon />,
            link: "/formulario",
          },
        ]
          .filter(Boolean) // Filtrar elementos nulos (si es tipo "D")
          .map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                component={Link}
                to={item.link}
                style={{ color: "white" }}
              >
                <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={signOut} style={{ color: "white" }}>
            <ListItemIcon sx={{ color: "white" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Salir" />
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          bgcolor: "#3F51B5",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Ventix Administración
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              top: 0,
              height: "100%",
              bgcolor: "#3F51B5",
              color: "white",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 2 }}
      >
        <Toolbar />
        {/* Aquí puedes renderizar el contenido principal de la página */}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
  signOut: PropTypes.func.isRequired,
};

export default ResponsiveDrawer;
