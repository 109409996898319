import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  TableSortLabel,
  Modal,
  Backdrop,
  Fade,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import theme_principal from "../themes";
import { useUser } from "../userContext";
import { useLocation } from 'react-router-dom';
import MultiSelect from "../components/multi_select";
const EmpresaDetalles = () => {
  const location = useLocation();

  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [empresasData, setEmpresasData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const [selectedClient, setSelectedClient] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [statusFilter, setStatusFilter] = useState("T");
  const [planFilter, setPlanFilter] = useState([]);
  const [minImporte, setMinImporte] = useState("");
  const [maxImporte, setMaxImporte] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const filtro = queryParams.get('filtro'); // 'filtro' será 'A', 'D', 'S', 'C', 'E'
  const tiposPlanes = ["Anual", "Mensual", "Demo"];

  useEffect(() => {
    const fetchData = async () => {
      setPlanFilter(tiposPlanes)
      if (!user || !user.id) {
        setLoading(false);
        return;
      }

      setError(null);

      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL_ADMIN + "/empresas",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id_usuario: user.id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Sucedió un error al obtener los datos.");
        }
        const data = await response.json();
        console.log(data);
        const sortedData = data
          .sort((a, b) => {
            return a.nombre_empresa.localeCompare(b.nombre_empresa);
          })
          .map(crearItemBusqueda);
        setEmpresasData(sortedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    if (filtro) {
      if (filtro === 'Demo') {
        setStatusFilter('T');
        setPlanFilter(['Demo']);
        return;
      }
      if (filtro === 'Activa'){
        setPlanFilter(tiposPlanes.filter(plan => plan !== 'Demo'));
      }
      
      setStatusFilter(filtro);
    }

  }, [user]);

  function crearItemBusqueda(empresa) {
    const campos = [
      empresa.nombre_empresa,
      empresa.usuario,
      empresa.distribuidor,
      empresa.suscripcion.plan,
      empresa.suscripcion.cantidad_dispositivos,
      empresa.suscripcion.estatus,
      formatDate(empresa.suscripcion.fecha_inicio),
      formatDate(empresa.suscripcion.fecha_fin),
      formatCurrency(empresa.suscripcion.importe),
    ];
    empresa.busqueda = campos.join(" ").toLowerCase();
    return empresa;
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filtroDesdeDashboard = (filtro) => {
    setSearchTerm(filtro);
  }

  const handleSort = (column, suscripcion) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);

    const sortedData = [...empresasData].sort((a, b) => {
      if (suscripcion) {
        if (
          a.suscripcion[column].toLowerCase() <
          b.suscripcion[column].toLowerCase()
        )
          return isAsc ? -1 : 1;
        if (
          a.suscripcion[column].toLowerCase() >
          b.suscripcion[column].toLowerCase()
        )
          return isAsc ? 1 : -1;
      } else {
        if (a[column].toLowerCase() < b[column].toLowerCase()) return isAsc ? -1 : 1;
        if (a[column].toLowerCase() > b[column].toLowerCase()) return isAsc ? 1 : -1;
      }
      return 0;
    });

    setEmpresasData(sortedData);
  };

  const formatDate = (date) => {
    if (!date) return " - ";

    const [year, month, day] = date.split("-");
    const año = parseInt(year, 10);
    const mes = parseInt(month, 10) - 1;
    const dia = parseInt(day, 10);

    if (isNaN(año) || isNaN(mes) || isNaN(dia)) {
      return " - " + date;
    }

    const newDate = new Date(año, mes, dia);
    return newDate.toLocaleDateString("es-MX", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value);
  };

  const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const stringText = text.toString();
    const parts = stringText.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handlePlanFilterChange = (selectedPlans) => {
    setPlanFilter(selectedPlans);
  };

  const handleMinImporteChange = (event) => {
    setMinImporte(event.target.value);
  };

  const handleMaxImporteChange = (event) => {
    setMaxImporte(event.target.value);
  };

  const filteredEmpresas = empresasData.filter((empresa) => {
    const matchesSearchTerm = empresa.busqueda.includes(
      searchTerm.toLowerCase()
    );
    const matchesStatus =
      statusFilter === "T" || empresa.suscripcion.estatus === statusFilter;

  
    const matchesPlan = 
      planFilter.length === 0 || planFilter.includes(empresa.suscripcion.plan);
    const matchesImporte =
      (!minImporte || empresa.suscripcion.importe >= parseFloat(minImporte)) &&
      (!maxImporte || empresa.suscripcion.importe <= parseFloat(maxImporte));

    return matchesSearchTerm && matchesStatus && matchesPlan && matchesImporte;
  });

  const paginatedEmpresas = filteredEmpresas.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleRowClick = (empresa) => {
    setSelectedClient(empresa);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const calculateTotalImporte = () => {
    return paginatedEmpresas.reduce(
      (total, empresa) => total + empresa.suscripcion.importe,
      0
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ textAlign: "left", mb: 1 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 1, userSelect: "none" }}
        >
          Suscripciones
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: "text.secondary", mb: 2 }}
        >
          Información de las suscripciones de cada cliente.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
            flexWrap: "wrap",
          }}
        >
          <TextField
            id="search"
            label="Buscar por cualquier campo..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "70%", mr: 2 }}
            InputProps={{
              endAdornment: (
                <Search sx={{ color: theme_principal.palette.primary.main }} />
              ),
            }}
          />
          <FormControl sx={{ minWidth: 120, mr: 2 }}>
            <InputLabel>Estatus</InputLabel>
            <Select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              label="Estatus"
            >
              <MenuItem value="T">Todas</MenuItem>
              <MenuItem value="Activa">Activas</MenuItem>
              <MenuItem value="Expirada">Expiradas</MenuItem>
              <MenuItem value="Suspendida">Suspendidas</MenuItem>
              <MenuItem value="Cancelada">Canceladas</MenuItem>
            </Select>
          </FormControl>
          <MultiSelect
            label="Plan"
            options={tiposPlanes}
            selectedOptions={planFilter}
            onChange={handlePlanFilterChange}
          />
{/*           <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Importe Mínimo"
            variant="outlined"
            size="small"
            type="number"
            value={minImporte}
            onChange={handleMinImporteChange}

            sx={{ width: "15%", mr: 2 }}
          />
          <TextField
            label="Importe Máximo"
            variant="outlined"
            size="small"
            type="number"
            value={maxImporte}
            onChange={handleMaxImporteChange}
            sx={{ width: "15%" }}
          />
          </Box>
 */}        </Box>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <>
          {paginatedEmpresas.length === 0 ? (
            <Typography
              variant="body1"
              color="error"
              sx={{ textAlign: "center", mt: 5 }}
            >
              No se encontraron empresas.
            </Typography>
          ) : (
            <>
              <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: theme_principal.palette.primary.light,
                      }}
                    >
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("nombre_empresa", false)}
                        sortDirection={
                          orderBy === "nombre_empresa" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "nombre_empresa"}
                          direction={
                            orderBy === "nombre_empresa" ? order : "asc"
                          }
                        >
                          Cliente
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("plan", true)}
                        sortDirection={orderBy === "plan" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "plan"}
                          direction={orderBy === "plan" ? order : "asc"}
                        >
                          Plan
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() =>
                          handleSort("cantidad_dispositivos", true)
                        }
                        sortDirection={
                          orderBy === "cantidad_dispositivos" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "cantidad_dispositivos"}
                          direction={
                            orderBy === "cantidad_dispositivos" ? order : "asc"
                          }
                        >
                          Dispositivos
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("distribuidor", false)}
                        sortDirection={
                          orderBy === "distribuidor" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "distribuidor"}
                          direction={orderBy === "distribuidor" ? order : "asc"}
                        >
                          Distribuidor
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("estatus", true)}
                        sortDirection={orderBy === "estatus" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "estatus"}
                          direction={orderBy === "estatus" ? order : "asc"}
                        >
                          Estatus
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("fecha_inicio", true)}
                        sortDirection={
                          orderBy === "fecha_inicio" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "fecha_inicio"}
                          direction={
                            orderBy === "fecha_inicio" ? order : "asc"
                          }
                        >
                          Fecha Inicio
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("fecha_fin", true)}
                        sortDirection={orderBy === "fecha_fin" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "fecha_fin"}
                          direction={orderBy === "fecha_fin" ? order : "asc"}
                        >
                          Fecha Fin
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("importe", true)}
                        sortDirection={orderBy === "importe" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "importe"}
                          direction={orderBy === "importe" ? order : "asc"}
                        >
                          Importe
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedEmpresas.map((empresa, index) => (
                      <TableRow
                        key={index}
                        hover
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleRowClick(empresa)}
                      >
                        <TableCell size="medium">
                          {highlightText(empresa.nombre_empresa, searchTerm)}
                        </TableCell>
                        <TableCell>
                          {highlightText(empresa.suscripcion.plan, searchTerm)}
                        </TableCell>
                        <TableCell
                          align="right"
                          size="small"
                          sx={{ width: "5%" }}
                        >
                          {highlightText(
                            empresa.suscripcion.cantidad_dispositivos,
                            searchTerm
                          )}
                        </TableCell>
                        <TableCell>
                          {highlightText(empresa.distribuidor, searchTerm)}
                        </TableCell>
                        <TableCell>
                          {highlightText(
                            empresa.suscripcion.estatus,
                            searchTerm
                          )}
                        </TableCell>
                        <TableCell>
                          {highlightText(
                            formatDate(empresa.suscripcion.fecha_inicio),
                            searchTerm
                          )}
                        </TableCell>
                        <TableCell>
                          {highlightText(
                            formatDate(empresa.suscripcion.fecha_fin),
                            searchTerm
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {highlightText(
                            formatCurrency(empresa.suscripcion.importe),
                            searchTerm
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={7} align="right" sx={{ fontWeight: "bold" }}>
                        Total:
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        {formatCurrency(calculateTotalImporte())}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={filteredEmpresas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página"
              />
            </>
          )}
        </>
      )}

      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
              borderRadius: "4px",
              overflow: "auto",
            }}
          >
            {selectedClient && (
              <>
                <Typography variant="h6" component="h2" align="center">
                  Datos del cliente
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Nombre:</strong> {selectedClient.nombre_empresa}
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Nombre comercial:</strong>{" "}
                  {selectedClient.nombre_comercial}
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Dirección:</strong> {selectedClient.direccion}
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Email:</strong> {selectedClient.correo_usuario}
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>RFC:</strong> {selectedClient.rfc}
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Régimen fiscal:</strong> {selectedClient.reg_fiscal}
                </Typography>
                <Typography variant="h6" component="h2" align="center">
                  Datos de la suscripción
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Tipo de suscripción:</strong>{" "}
                  {selectedClient.suscripcion.plan}{" "}
                  {" - "}{selectedClient.suscripcion.estatus}
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Fecha último cobro:</strong>{" "}
                  {formatDate(selectedClient.suscripcion.ultimo_cobro)}
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Fecha último pago:</strong>{" "}
                  {formatDate(selectedClient.suscripcion.fecha_ultimo_pago)}
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Importe:</strong>
                  <span>{formatCurrency(selectedClient.suscripcion.importe)}</span>
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Distribuidor:</strong>
                  <span>{selectedClient.distribuidor}</span>
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Inicio de suscripción:</strong>
                  <span>
                    {formatDate(selectedClient.suscripcion.fecha_creacion)}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <strong>Fin de suscripción:</strong>
                  <span>
                    {formatDate(selectedClient.suscripcion.fecha_termino)}
                  </span>
                </Typography>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

export default EmpresaDetalles;
