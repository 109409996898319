import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchUserAttributes } from "aws-amplify/auth";
import { signOut } from "aws-amplify/auth";
import axios from "axios";
import { useLocation } from "react-router-dom";


const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (location.pathname === "/registro") {
        setLoading(false);
        return;
      }

      try {
        const attributes = await fetchUserAttributes();
        if (attributes.email) {
          const userData = await getInfoUser(attributes.email);
          setUser(userData);
        }
      } catch (error) {
        console.error("Error de autenticación:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [location.pathname]);

  const getInfoUser = async (email) => {
    const url =
    process.env.REACT_APP_API_URL + "/usuario";
    const body = { idUser: email };

    try {
      const response = await axios.post(url, body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (response.data.user.tipo === "C") {
        signOut();
      }

      return response.data.user;
    } catch (error) {
      console.log(url);
      console.error("Error al obtener datos del usuario:", error);
      throw error;
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export { UserContext };
