import React, { useState } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Grid,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import theme_principal from "../themes";

const PromoCodeForm = () => {
  const [formData, setFormData] = useState({
    discountValue: "",
    duration: "",
    durationQuantity: "",
    uses: "",
  });

  const [formErrors, setFormErrors] = useState({
    discountValue: "",
    durationQuantity: "",
    uses: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    // Limpiar mensaje de error cuando se cambia el valor
    setFormErrors({
      ...formErrors,
      [name]: "",
    });
  };

  const handleGenerateCode = async () => {
    // Validaciones de campos
    let valid = true;

    if (
      formData.discountValue.trim() === "" ||
      parseFloat(formData.discountValue) <= 0
    ) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        discountValue: "Ingresa un valor de descuento válido.",
      }));
      valid = false;
    }

    if (parseInt(formData.durationQuantity, 10) <= 0) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        durationQuantity: "Ingresa una cantidad válida para la duración.",
      }));
      valid = false;
    }

    if (parseInt(formData.uses, 10) <= 0) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        uses: "Ingresa un número válido de usos.",
      }));
      valid = false;
    }

    if (!valid) {
      return;
    }

    const dataToSend = {
      id_distribuidor: 97,
      porcentaje: parseFloat(formData.discountValue),
      duracion: `${formData.duration}-${formData.durationQuantity}`,
      usos: parseInt(formData.uses, 10),
      tipo: "C",
    };

    try {
      setIsSubmitting(true);
      const response = await axios.post(
        process.env.REACT_APP_API_URL_ADMIN+ "/distribuidor/codigos/crear",
        dataToSend
      );
      setGeneratedCode(response.data.codigo);
      setOpenModal(true);
    } catch (error) {
      console.error("Error generando el código:", error);
      alert("Hubo un error al generar el código.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setGeneratedCode("");
    setFormData({
      discountValue: "",
      duration: "",
      durationQuantity: "",
      uses: "",
    });
    setFormErrors({
      discountValue: "",
      durationQuantity: "",
      uses: "",
    });
  };

  return (
    <Box
      sx={{
        p: 4,
        borderRadius: 2,
        boxShadow: 3,
        maxWidth: 600,
        margin: "auto",
        mt: 5,
        backgroundColor: "background.paper",
      }}
    >
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{ marginBottom: 3, textAlign: "center", color: "#333" }}
      >
        Crear un código promocional
      </Typography>
      <Typography
        variant="body1"
        component="p"
        gutterBottom
        sx={{ marginBottom: 4, textAlign: "center", color: "#666" }}
      >
        Ingresa los detalles del código promocional
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            error={!!formErrors.discountValue}
            helperText={formErrors.discountValue}
            label="Porcentaje de descuento"
            type="number"
            name="discountValue"
            value={formData.discountValue}
            onChange={handleFormChange}
            placeholder="Valor"
            variant="outlined"
            fullWidth
            InputProps={{ style: { borderRadius: 8, textAlign: "left" } }}
            inputProps={{ min: 1 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            select
            label="Duración"
            name="duration"
            value={formData.duration}
            onChange={handleFormChange}
            variant="outlined"
            fullWidth
            InputProps={{ style: { borderRadius: 8, textAlign: "left" } }}
          >
            <MenuItem value="Y">Año</MenuItem>
            <MenuItem value="M">Mes</MenuItem>
            <MenuItem value="W">Semanas</MenuItem>
            <MenuItem value="D">Días</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            error={!!formErrors.durationQuantity}
            helperText={formErrors.durationQuantity}
            label={`Cantidad de ${
              formData.duration === "Y"
                ? "años"
                : formData.duration === "M"
                ? "meses"
                : formData.duration === "W"
                ? "semanas"
                : "días"
            }`}
            type="number"
            name="durationQuantity"
            value={formData.durationQuantity}
            onChange={handleFormChange}
            placeholder="Cantidad"
            variant="outlined"
            fullWidth
            InputProps={{ style: { borderRadius: 8, textAlign: "left" } }}
            inputProps={{ min: 1 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            error={!!formErrors.uses}
            helperText={formErrors.uses}
            label="Usos"
            type="number"
            name="uses"
            value={formData.uses}
            onChange={handleFormChange}
            placeholder="Usos"
            variant="outlined"
            fullWidth
            InputProps={{ style: { borderRadius: 8, textAlign: "left" } }}
            inputProps={{ min: 1 }}
          />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3, gap: 2 }}>
        <Button
          variant="contained"
          onClick={handleGenerateCode}
          sx={{
            py: 1.5,
            px: 4,
            bgcolor: theme_principal.palette.primary.main,
            "&:hover": {
              bgcolor: theme_principal.palette.hover.main,
            },
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? <CircularProgress size={24} /> : "GENERAR CÓDIGO"}
        </Button>
      </Box>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          "& .MuiDialog-paper": { marginLeft: "240px" },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Código generado
          <IconButton onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1.2rem", textAlign: "center" }}>
            El código promocional generado es: <strong>{generatedCode}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            sx={{
              py: 1.5,
              px: 4,
              bgcolor: theme_principal.palette.primary.main,
              "&:hover": {
                bgcolor: theme_principal.palette.hover.main,
              },
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PromoCodeForm;
