import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  CircularProgress,
  ThemeProvider,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { signUp } from "aws-amplify/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import theme_principal from "../themes";
import axios from "axios";

const token = "ab0109986b71ada75f9f544ec6aa78a6fa506a73";

const Formulario = () => {
  const [formData, setFormData] = useState({
    nombre: "",
    apellidoP: "",
    apellidoM: "",
    correo: "",
    telefono: "",
    calle: "",
    codigoPostal: "",
    ciudad: "",
    colonia: "",
    estado: "",
  });

  const limpiaDatos = () => {
    setFormData({
      nombre: "",
      apellidoP: "",
      apellidoM: "",
      correo: "",
      telefono: "",
      calle: "",
      codigoPostal: "",
      ciudad: "",
      colonia: "",
      estado: "",
    });
  };

  const [colonias, setColonias] = useState([]);
  const [isOtherColonia, setIsOtherColonia] = useState(false);
  const [isOtherCiudad, setIsOtherCiudad] = useState(false);
  const [isOtherEstado, setIsOtherEstado] = useState(false);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));

    if (id === "codigoPostal" && value.length === 5) {
      fetchLocationData(value);
    }
  };

  const handleSelectChange = (e, field) => {
    const { value } = e.target;
    if (value === "other") {
      switch (field) {
        case "colonia":
          setIsOtherColonia(true);
          break;
        case "ciudad":
          setIsOtherCiudad(true);
          break;
        case "estado":
          setIsOtherEstado(true);
          break;
        default:
          break;
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const fetchLocationData = async (codigoPostal) => {
    try {
      const response = await axios.get(
        `/dipomex/v1/codigo_postal?cp=${codigoPostal}`,
        {
          headers: {
            APIKEY: token,
          },
        }
      );
      if (response.data && response.data.codigo_postal) {
        // console.log(response.data);
        const { estado, municipio, colonias } = response.data.codigo_postal;
        setColonias(colonias);
        setFormData((prevData) => ({
          ...prevData,
          ciudad: municipio,
          estado: estado,
          colonia: "",
        }));
        setIsOtherColonia(false);
        setIsOtherCiudad(false);
        setIsOtherEstado(false);
      } else {
        toast.error("No se encontró información para este código postal.");
        setIsOtherCiudad(true);
        setIsOtherEstado(true);
        setIsOtherColonia(true);
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
      toast.error(
        "Error al obtener datos de ubicación. Intente de nuevo más tarde."
      );
      setIsOtherCiudad(true);
      setIsOtherEstado(true);
      setIsOtherColonia(true);
    }
  };

  const validate = () => {
    let tempErrors = {};

    // Validación de Nombre
    if (!formData.nombre) {
      tempErrors.nombre = "Nombre es requerido";
    } else if (formData.nombre.length < 2) {
      tempErrors.nombre = "Nombre debe tener al menos 2 caracteres";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.nombre)) {
      tempErrors.nombre = "Nombre solo puede contener letras y espacios";
    }

    // Validación de Apellido Paterno
    if (!formData.apellidoP) {
      tempErrors.apellidoP = "Apellido Paterno es requerido";
    } else if (formData.apellidoP.length < 2) {
      tempErrors.apellidoP =
        "Apellido Paterno debe tener al menos 2 caracteres";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.apellidoP)) {
      tempErrors.apellidoP =
        "Apellido Paterno solo puede contener letras y espacios";
    }

    // Validación de Apellido Materno
    if (!formData.apellidoM) {
      tempErrors.apellidoM = "Apellido Materno es requerido";
    } else if (formData.apellidoM.length < 2) {
      tempErrors.apellidoM =
        "Apellido Materno debe tener al menos 2 caracteres";
    } else if (!/^[a-zA-Z\s]+$/.test(formData.apellidoM)) {
      tempErrors.apellidoM =
        "Apellido Materno solo puede contener letras y espacios";
    }

    // Validación de Correo Electrónico
    if (!formData.correo) {
      tempErrors.correo = "Correo Electrónico es requerido";
    } else if (!/\S+@\S+\.\S+/.test(formData.correo)) {
      tempErrors.correo = "Correo Electrónico no es válido";
    }

    // Validación de Teléfono
    if (!formData.telefono) {
      tempErrors.telefono = "Teléfono es requerido";
    } else if (!/^\d{10}$/.test(formData.telefono)) {
      tempErrors.telefono = "Teléfono debe tener 10 dígitos";
    }

    // Validación de Calle
    if (!formData.calle) {
      tempErrors.calle = "Calle es requerida";
    } else if (formData.calle.length < 3) {
      tempErrors.calle = "Calle debe tener al menos 3 caracteres";
    }

    // Validación de Código Postal
    if (!formData.codigoPostal) {
      tempErrors.codigoPostal = "Código Postal es requerido";
    } else if (!/^\d{5}$/.test(formData.codigoPostal)) {
      tempErrors.codigoPostal = "Código Postal debe tener 5 dígitos";
    }

    // Validación de Colonia
    if (!formData.colonia) {
      tempErrors.colonia = "Colonia es requerida";
    } else if (formData.colonia.length < 2) {
      tempErrors.colonia = "Colonia debe tener al menos 2 caracteres";
    }

    // Validación de Ciudad
    if (!formData.ciudad) {
      tempErrors.ciudad = "Ciudad es requerida";
    } else if (formData.ciudad.length < 2) {
      tempErrors.ciudad = "Ciudad debe tener al menos 2 caracteres";
    }

    // Validación de Estado
    if (!formData.estado) {
      tempErrors.estado = "Estado es requerido";
    } else if (formData.estado.length < 2) {
      tempErrors.estado = "Estado debe tener al menos 2 caracteres";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const createDistribuidor = async (distribuidorData) => {
    const url =
    process.env.REACT_APP_API_URL_ADMIN+ "/distribuidor/crear";
    try {
      // console.log("Data enviada:", JSON.stringify(distribuidorData, null, 2));
      const response = await axios.post(url, distribuidorData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      // console.log("Distribuidor creado correctamente:", response.data);
      toast.success("Distribuidor creado correctamente");
      return true;
    } catch (error) {
      console.error("Error al crear distribuidor:", error);
      toast.error("Error al crear distribuidor: " + error.message);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true);
      try {
        await signUp({
          username: formData.correo,
          password: "temporal",
          attributes: {
            given_name: formData.nombre,
            family_name: `${formData.apellidoP} ${formData.apellidoM}`,
          },
        });
        // console.log("Usuario registrado correctamente en Cognito");

        const distribuidorData = {
          nombre: formData.nombre,
          apellido_paterno: formData.apellidoP,
          apellido_materno: formData.apellidoM,
          usuario: formData.correo,
          dir: {
            calle: formData.calle,
            colonia: formData.colonia,
            codigo_postal: formData.codigoPostal,
            ciudad: formData.ciudad,
            estado: formData.estado,
          },
          telefono: formData.telefono,
        };

        await createDistribuidor(distribuidorData);

        limpiaDatos();
      } catch (error) {
        console.error(
          "Error durante el registro del usuario y distribuidor:",
          error
        );
        toast.error("Ocurrió un error durante el registro: " + error.message);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  return (
    <ThemeProvider theme={theme_principal}>
      <Box
        component="form"
        sx={{
          p: 3,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: 600,
          margin: "auto",
          mt: 5,
          backgroundColor: "background.paper",
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Crea un distribuidor
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="nombre"
              label="Nombre"
              variant="outlined"
              fullWidth
              required
              value={formData.nombre}
              onChange={handleChange}
              error={!!errors.nombre}
              helperText={errors.nombre}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="apellidoP"
              label="Apellido Paterno"
              variant="outlined"
              fullWidth
              required
              value={formData.apellidoP}
              onChange={handleChange}
              error={!!errors.apellidoP}
              helperText={errors.apellidoP}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="apellidoM"
              label="Apellido Materno"
              variant="outlined"
              fullWidth
              required
              value={formData.apellidoM}
              onChange={handleChange}
              error={!!errors.apellidoM}
              helperText={errors.apellidoM}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="correo"
              label="Correo Electrónico"
              variant="outlined"
              fullWidth
              required
              type="email"
              value={formData.correo}
              onChange={handleChange}
              error={!!errors.correo}
              helperText={errors.correo}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="telefono"
              label="Teléfono"
              variant="outlined"
              fullWidth
              required
              value={formData.telefono}
              onChange={handleChange}
              error={!!errors.telefono}
              helperText={errors.telefono}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="calle"
              label="Calle y Número"
              variant="outlined"
              fullWidth
              required
              value={formData.calle}
              onChange={handleChange}
              error={!!errors.calle}
              helperText={errors.calle}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="codigoPostal"
              label="Código Postal"
              variant="outlined"
              fullWidth
              required
              value={formData.codigoPostal}
              onChange={handleChange}
              error={!!errors.codigoPostal}
              helperText={errors.codigoPostal}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {isOtherColonia ? (
              <TextField
                id="colonia"
                label="Colonia"
                variant="outlined"
                fullWidth
                required
                value={formData.colonia}
                onChange={handleChange}
                error={!!errors.colonia}
                helperText={errors.colonia}
              />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.colonia}
                style={{ textAlign: "left" }}
              >
                <InputLabel id="colonia-label">Colonia</InputLabel>
                <Select
                  labelId="colonia-label"
                  id="colonia"
                  value={formData.colonia}
                  onChange={(e) => handleSelectChange(e, "colonia")}
                  label="Colonia"
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        textAlign: "left",
                      },
                    },
                  }}
                >
                  {colonias.map((colonia, index) => (
                    <MenuItem
                      key={index}
                      value={colonia}
                      style={{ textAlign: "left" }}
                    >
                      {colonia}
                    </MenuItem>
                  ))}
                  <MenuItem value="other" style={{ textAlign: "left" }}>
                    Otra
                  </MenuItem>
                </Select>
                <FormHelperText>{errors.colonia}</FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isOtherCiudad ? (
              <TextField
                id="ciudad"
                label="Ciudad"
                variant="outlined"
                fullWidth
                required
                value={formData.ciudad}
                onChange={handleChange}
                error={!!errors.ciudad}
                helperText={errors.ciudad}
              />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.ciudad}
                style={{ textAlign: "left" }}
              >
                <InputLabel id="ciudad-label">Ciudad</InputLabel>
                <Select
                  labelId="ciudad-label"
                  id="ciudad"
                  value={formData.ciudad}
                  onChange={(e) => handleSelectChange(e, "ciudad")}
                  label="Ciudad"
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        textAlign: "left",
                      },
                    },
                  }}
                >
                  <MenuItem
                    value={formData.ciudad}
                    style={{ textAlign: "left" }}
                  >
                    {formData.ciudad}
                  </MenuItem>
                  <MenuItem value="other" style={{ textAlign: "left" }}>
                    Otra
                  </MenuItem>
                </Select>
                <FormHelperText>{errors.ciudad}</FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isOtherEstado ? (
              <TextField
                id="estado"
                label="Estado"
                variant="outlined"
                fullWidth
                required
                value={formData.estado}
                onChange={handleChange}
                error={!!errors.estado}
                helperText={errors.estado}
              />
            ) : (
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errors.estado}
                style={{ textAlign: "left" }}
              >
                <InputLabel id="estado-label">Estado</InputLabel>
                <Select
                  labelId="estado-label"
                  id="estado"
                  value={formData.estado}
                  onChange={(e) => handleSelectChange(e, "estado")}
                  label="Estado"
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        textAlign: "left",
                      },
                    },
                  }}
                >
                  <MenuItem
                    value={formData.estado}
                    style={{ textAlign: "left" }}
                  >
                    {formData.estado}
                  </MenuItem>
                  <MenuItem value="other" style={{ textAlign: "left" }}>
                    Otro
                  </MenuItem>
                </Select>
                <FormHelperText>{errors.estado}</FormHelperText>
              </FormControl>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                position: "relative",
                display: "inline-flex",
                width: "100%",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isSubmitting}
                sx={{
                  mt: 2,
                  bgcolor: theme_principal.palette.primary.main,
                  "&:hover": {
                    bgcolor: theme_principal.palette.hover.main,
                  },
                }}
              >
                Crear Distribuidor
              </Button>
              {isSubmitting && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "primary.main",
                    position: "absolute",
                    top: "65%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </ThemeProvider>
  );
};

export default Formulario;
