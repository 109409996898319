import React from "react";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Collapse,
  Typography,
} from "@mui/material";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Star,
  StarBorder,
  Search,
} from "@mui/icons-material";

const createData = (
  nombre,
  apellido,
  head,
  rfc,
  fechaCreacion,
  importe,
  suscripciones,
  empresas
) => {
  return {
    nombre,
    apellido,
    head,
    rfc,
    fechaCreacion,
    importe,
    suscripciones,
    empresas,
  };
};

const rows = [
  createData("Sebas", "Ramirez", "Cell", "Cell", "Cell", "Cell", "Cell", [
    {
      nombreEmpresa: "Empresa 1",
      cantidadDispositivos: 14,
      fechaCreacion: "07/07/33",
      estatus: "Activo",
      fechaCobro: "07/07/33",
    },
    {
      nombreEmpresa: "Empresa 2",
      cantidadDispositivos: 31,
      fechaCreacion: "07/07/33",
      estatus: "Cancelado",
      fechaCobro: "07/07/33",
    },
  ]),
  createData("Yordi", "Ortiz", "Cell", "Cell", "Cell", "Cell", "Cell", [
    {
      nombreEmpresa: "Empresa 3",
      cantidadDispositivos: 20,
      fechaCreacion: "07/07/34",
      estatus: "Activo",
      fechaCobro: "07/07/34",
    },
  ]),
];

const CollapsibleTable = () => {
  const [openRow, setOpenRow] = React.useState(null);
  const [favorites, setFavorites] = React.useState([]);

  const handleRowClick = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const toggleFavorite = (index) => {
    setFavorites((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ textAlign: "left", mb: 3, px: 2 }}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 1 }}
        >
          Usuarios
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: "text.secondary", mb: 2 }}
        >
          Información de los usuarios y sus empresas
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            mb: 2,
          }}
        >
          <Search sx={{ color: "primary.main", mr: 1 }} />
          <TextField
            id="search"
            label="Nombre, email, etc..."
            variant="outlined"
            size="small"
            sx={{ width: "300px", mr: 1 }}
          />
          <Button variant="contained" color="primary">
            Buscar
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Nombre</TableCell>
              <TableCell>Apellido</TableCell>
              <TableCell>Head</TableCell>
              <TableCell>Rfc</TableCell>
              <TableCell>Fecha Creación</TableCell>
              <TableCell>Importe</TableCell>
              <TableCell># Suscripciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>
                    <IconButton onClick={() => handleRowClick(index)}>
                      {openRow === index ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                    <IconButton onClick={() => toggleFavorite(index)}>
                      {favorites.includes(index) ? <Star /> : <StarBorder />}
                    </IconButton>
                  </TableCell>
                  <TableCell>{row.nombre}</TableCell>
                  <TableCell>{row.apellido}</TableCell>
                  <TableCell>{row.head}</TableCell>
                  <TableCell>{row.rfc}</TableCell>
                  <TableCell>{row.fechaCreacion}</TableCell>
                  <TableCell>{row.importe}</TableCell>
                  <TableCell>{row.suscripciones}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={8}
                  >
                    <Collapse
                      in={openRow === index}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          Detalles de Empresa
                        </Typography>
                        <Table size="small" aria-label="empresa">
                          <TableHead>
                            <TableRow>
                              <TableCell>Nombre de empresa</TableCell>
                              <TableCell>Cantidad de Dispositivos</TableCell>
                              <TableCell>Fecha de creación</TableCell>
                              <TableCell>Estatus</TableCell>
                              <TableCell>Fecha de cobro</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {row.empresas.map((empresa, empresaIndex) => (
                              <TableRow key={empresaIndex}>
                                <TableCell>{empresa.nombreEmpresa}</TableCell>
                                <TableCell>
                                  {empresa.cantidadDispositivos}
                                </TableCell>
                                <TableCell>{empresa.fechaCreacion}</TableCell>
                                <TableCell>{empresa.estatus}</TableCell>
                                <TableCell>{empresa.fechaCobro}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CollapsibleTable;
