import { createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery

const theme_principal = createTheme({
  palette: {
    primary: {
      main: "#3F51B5",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#f5f5f5",
    },
    hover: {
      main: "#5C6BC0",
    },
  },
  // Remove explicit mode setting here
});

// Create custom hook for dynamic theme handling
export function useDynamicTheme() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = createTheme({
    ...theme_principal,
    palette: {
      ...theme_principal.palette,
      mode: "dark",
    },
  });
  return theme;
}

export default theme_principal; // Export the default theme
