import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  TablePagination,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import theme_principal from "../themes";
import { useUser } from "../userContext";

const DistribuidorDetalles = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [distribuidoresData, setDistribuidoresData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !user.id) {
        setLoading(false);
        return;
      }

      setError(null);

      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL_ADMIN+ "/distribuidor",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id_usuario: user.id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const distribuidoresArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        distribuidoresArray.sort((a, b) => (a.nombre > b.nombre ? 1 : -1));

        // console.log("distribuidoresArray", distribuidoresArray);

        setDistribuidoresData(distribuidoresArray);
      } catch (err) {
        setError(err.message);
        console.error("Error al obtener datos de los distribuidores:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const filteredDistribuidores = distribuidoresData.filter((distribuidor) => {
    const lowerCaseTerm = searchTerm.toLowerCase();
    return (
      (distribuidor.nombre &&
        distribuidor.nombre.toLowerCase().includes(lowerCaseTerm)) ||
      (distribuidor.apellido_paterno &&
        distribuidor.apellido_paterno.toLowerCase().includes(lowerCaseTerm)) ||
      (distribuidor.apellido_materno &&
        distribuidor.apellido_materno.toLowerCase().includes(lowerCaseTerm)) ||
      (distribuidor.activo ? "Sí" : "No")
        .toLowerCase()
        .includes(lowerCaseTerm) ||
      (distribuidor.usuario_fecha_creacion &&
        distribuidor.usuario_fecha_creacion
          .toLowerCase()
          .includes(lowerCaseTerm)) ||
      (distribuidor.dir.calle &&
        distribuidor.dir.calle.toLowerCase().includes(lowerCaseTerm)) ||
      (distribuidor.dir.colonia &&
        distribuidor.dir.colonia.toLowerCase().includes(lowerCaseTerm)) ||
      (distribuidor.dir.codigo_postal &&
        distribuidor.dir.codigo_postal.toLowerCase().includes(lowerCaseTerm)) ||
      (distribuidor.dir.ciudad &&
        distribuidor.dir.ciudad.toLowerCase().includes(lowerCaseTerm)) ||
      (distribuidor.dir.estado &&
        distribuidor.dir.estado.toLowerCase().includes(lowerCaseTerm))
    );
  });

  const paginatedDistribuidores = filteredDistribuidores.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleEdit = (distribuidor) => {
    navigate(`/editar-distribuidor/${distribuidor.id}`, {
      state: { distribuidor },
    });
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          Distribuidores
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: "text.secondary", mb: 3 }}
        >
          Información de los distribuidores y sus detalles
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <TextField
            id="search"
            label="Buscar por cualquier campo..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "70%", mr: 2 }}
            InputProps={{
              endAdornment: (
                <Search sx={{ color: theme_principal.palette.primary.main }} />
              ),
            }}
          />
        </Box>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <>
          {paginatedDistribuidores.length === 0 && (
            <Typography
              variant="body1"
              color="error"
              sx={{ textAlign: "center", mt: 5 }}
            >
              No se encontraron distribuidores.
            </Typography>
          )}
          <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    backgroundColor: theme_principal.palette.primary.light,
                  }}
                >
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Nombre
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Apellido Paterno
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Apellido Materno
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Activo
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Fecha de Creación
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Calle
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Colonia
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Código Postal
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Ciudad
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Estado
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedDistribuidores.map((distribuidor, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                    }}
                  >
                    <TableCell>
                      {highlightText(distribuidor.nombre || " -", searchTerm)}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        distribuidor.apellido_paterno || " -",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        distribuidor.apellido_materno || " -",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        distribuidor.activo ? "Sí" : "No" || " -",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        distribuidor.usuario_fecha_creacion || " -",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        distribuidor.dir.calle || " -",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        distribuidor.dir.colonia || " -",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        distribuidor.dir.codigo_postal || " -",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        distribuidor.dir.ciudad || " -",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      {highlightText(
                        distribuidor.dir.estado || " -",
                        searchTerm
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        component={Link}
                        to={`/crear-codigo`}
                        sx={{
                          py: 0.5,
                          px: 1,
                          bgcolor: theme_principal.palette.primary.main,
                          color: "#fff",
                          "&:hover": {
                            bgcolor: theme_principal.palette.hover.main,
                          },
                        }}
                      >
                        Crear Código
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleEdit(distribuidor)}
                        sx={{
                          py: 0.5,
                          px: 1,
                          bgcolor: theme_principal.palette.secondary.main,
                          color: "#fff",
                          ml: 1,
                          "&:hover": {
                            bgcolor: theme_principal.palette.secondary.dark,
                          },
                        }}
                      >
                        Editar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage="Filas por página:"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredDistribuidores.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default DistribuidorDetalles;
