import "./App.css";
import React from "react";
import { Amplify } from "aws-amplify";
import {
  Button,
  Heading,
  Authenticator,
  View,
  Image,
  Tabs,
  TabItem,
} from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import {
  BrowserRouter as Router,
  Routes, 
  Route, 
  Navigate, 
  useLocation 
} from "react-router-dom";
import { CircularProgress, Box, Typography } from "@mui/material";
import { UserProvider, useUser } from "./userContext";
import logo from "./assets/img/ventix_logo.png";
import Formulario from "./pages/formulario";
import CollapsibleTable from "./pages/clientes";
import PromoCodeForm from "./pages/codigos-promocionales";
import EmpresaDetalles from "./pages/empresas";
import { ToastContainer } from "react-toastify";
import DistribuidorDetalles from "./pages/lista-distribuidores";
import Home from "./pages/home";
import PromoCodeList from "./pages/lista-codigos";
import Registro from "./pages/registro";
import EditDistribuidorForm from "./pages/editar-distribuidor";
import ResponsiveDrawer from "./components/sidebar";  // Asegúrate de que esta importación es correcta.

Amplify.configure(awsconfig);

function AppContent({ signOut }) {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#f0f2f5",
        }}
      >
        <CircularProgress size={60} />
        <Typography variant="h6" sx={{ mt: 2 }}>
          Cargando...
        </Typography>
      </Box>
    );
  }

  if (!user && location.pathname !== "/registro") {
    return <Navigate to="/registro" replace />;
  } else if (user && location.pathname === "/registro") {
    return <Navigate to="/" replace />;
  }

  const isRegistroRoute = location.pathname === "/registro";

  return (
    <div className={`App ${isRegistroRoute ? "registro-page" : ""}`}>
      {user && !isRegistroRoute && <ResponsiveDrawer signOut={signOut} />}
      <div className="content">
        <ToastContainer />
        <Routes>
          <Route path="/formulario" element={<Formulario />} />
          <Route path="/" element={<Home />} />
          <Route path="/distribuidores" element={<DistribuidorDetalles />} />
          <Route path="/empresas" element={<EmpresaDetalles />} />
          <Route path="/clientes" element={<CollapsibleTable />} />
          <Route path="/registro" element={<Registro />} />
          <Route path="/codigos-promocionales" element={<PromoCodeList />} />
          <Route path="/crear-codigo" element={<PromoCodeForm />} />
          <Route
            path="/editar-distribuidor/:id"
            element={<EditDistribuidorForm />}
          />

          <Route
            path="/login"
            element={<Heading level={1}>Iniciar sesión</Heading>}
          />
        </Routes>
      </div>
    </div>
  );
}

const components = {
  Header() {
    return (
      <View textAlign="center" padding="20px" backgroundColor="#f5f5f5">
        <Image alt="Ventix Logo" src={logo} width="300px" />
      </View>
    );
  },
  Tabs({ children }) {
    return (
      <Tabs justifyContent="center" backgroundColor="#3F51B5" color="#fff">
        {children
        }
      </Tabs>
    );
  },
  SignIn: {

  },
};

const formFields = {
  signIn: {
    username: {
      label: "Correo electrónico",
      placeholder: "Introduce tu correo electrónico",
    },
    password: {
      label: "Contraseña",
      placeholder: "Introduce tu contraseña",
    },
  },
  signUp: {
    email: {
      label: "Correo electrónico",
      placeholder: "Introduce tu correo electrónico",
    },
    password: {
      label: "Contraseña",
      placeholder: "Introduce tu contraseña",
    },
    confirm_password: {
      label: "Confirmar contraseña",
      placeholder: "Confirma tu contraseña",
    },
  },
  // Otros campos...
};

function App() {
  return (
    <Router>
      <Authenticator
        formFields={formFields}
        components={components}
        labels={{
          signIn: "Iniciar Sesión",
          signUp: "Crear Cuenta",
        }}
      >
        {({ signOut, user }) => (
          <UserProvider>
            <AppContent signOut={signOut} />
          </UserProvider>
        )}
      </Authenticator>
    </Router>
  );
}

export default App;
