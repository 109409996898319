import React from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import useWindowSize from './useWindowSize'; // Un custom hook para obtener el tamaño de la ventana

const Dashboard = ({ suscripciones }) => {
  const data = [
    { name: 'Activas', Cantidad: suscripciones.activas ? suscripciones.activas : 0, filterData: 'Activa'},
    { name: 'Demo', Cantidad: suscripciones.demo ? suscripciones.demo : 0, filterData: 'Demo' },
    { name: 'Suspendidas', Cantidad: suscripciones.suspendidas ? suscripciones.suspendidas : 0, filterData: 'Suspendida' },
    { name: 'Canceladas', Cantidad: suscripciones.canceladas ? suscripciones.canceladas : 0, filterData: 'Cancelada' },
    { name: 'Expiradas', Cantidad: suscripciones.expiradas ? suscripciones.expiradas : 0, filterData: 'Expirada'},
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF8042'];

  const total = data.reduce((acc, item) => acc + item.Cantidad, 0);

  const handleClick = (filterData) => {
    // Redirigir a /empresas mandando el dato de filterData
    if (filterData === "Todos") {
      filterData = "";
    }
    window.location.href = `/empresas?filtro=${filterData}`;
  };

  const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',
  };

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 600;

  const containerStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    gap: '20px',
  };

  const pieChartContainerStyle = {
    flex: 1,
    minHeight: isMobile ? '200px' : '300px',
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const pieChartStyle = {
    flex: 1,
    minHeight: isMobile ? '200px' : '300px',
    height: '100%',
    width: '100%',
  };

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2>Suscripciones</h2>

      <div style={containerStyle}>
        {/* Card para la Tabla */}
        <div style={{ ...cardStyle, flex: 1 }}>
          <h3>Resumen</h3>
          {data.map((item) => (
            <div
              key={item.name}
              style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', cursor: 'pointer' }}
              onClick={() => handleClick(item.filterData)}
            >
              <span>{item.name}:</span>
              <span>{item.Cantidad}</span>
            </div>
          ))}
          <hr />
          <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => handleClick("Todos")}>
            <span>Total:</span>
            <span>{total}</span>
          </div>
        </div>

        {/* Card para la Gráfica */}
        <div style={{ ...cardStyle, ...pieChartStyle }}>
          <div style={{ width: '100%', height: '300px', ...pieChartContainerStyle }}>
            <PieChart width={300} height={300}>
              <Pie
                data={data}
                dataKey="Cantidad"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={isMobile ? 60 : 100}
                fill="#8884d8"
                label
                onClick={(e, index) => handleClick(data[index].filterData)} // Añadido onClick aquí
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend verticalAlign="bottom" height={36} />
            </PieChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
