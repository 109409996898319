import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  TablePagination,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Search } from "@mui/icons-material";
import axios from "axios";
import theme_principal from "../themes";
import { useUser } from "../userContext";

const PromoCodeList = () => {
  const { user } = useUser();
  const [promoCodes, setPromoCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchPromoCodes = async () => {
      try {
        const response = await axios.post(
          "https://elaxz6uoc8.execute-api.us-east-2.amazonaws.com/prod/distribuidor/codigos",
          { id_usuario: user.id }
        );
        // console.log("response", response);
        setPromoCodes(response.data.codigos);
      } catch (error) {
        setError("Hubo un error al obtener los códigos promocionales.");
        console.error("Error fetching promo codes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPromoCodes();
  }, [user]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const parts = text.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const filteredPromoCodes = Array.isArray(promoCodes)
    ? promoCodes.filter((code) => {
        const lowerCaseTerm = searchTerm.toLowerCase();
        return (
          code.codigo.toLowerCase().includes(lowerCaseTerm) ||
          code.porcentaje.toString().includes(lowerCaseTerm) ||
          (code.estatus === "A" ? "Activo" : "Inactivo")
            .toLowerCase()
            .includes(lowerCaseTerm) ||
          code.fecha_vencimiento.toLowerCase().includes(lowerCaseTerm) ||
          code.veces_usado.toString().includes(lowerCaseTerm) ||
          code.usos_maximos.toString().includes(lowerCaseTerm)
        );
      })
    : [];

  const paginatedPromoCodes = filteredPromoCodes.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ textAlign: "left", mb: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 2 }}
        >
          Códigos promocionales
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: "text.secondary", mb: 3 }}
        >
          Información de los códigos promocionales creados.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
          }}
        >
          <TextField
            id="search"
            label="Buscar por cualquier campo..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "70%", mr: 2 }}
            InputProps={{
              endAdornment: (
                <Search sx={{ color: theme_principal.palette.primary.main }} />
              ),
            }}
          />
          {user.tipo !== "D" && (
            <Button
              variant="contained"
              component={Link}
              to="/crear-codigo"
              sx={{
                py: 1,
                px: 3,
                bgcolor: theme_principal.palette.primary.main,
                "&:hover": {
                  bgcolor: theme_principal.palette.hover.main,
                },
              }}
            >
              CREAR NUEVO CÓDIGO
            </Button>
          )}
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <>
          {paginatedPromoCodes.length === 0 ? (
            <Typography
              variant="body1"
              color="error"
              sx={{ textAlign: "center", mt: 5 }}
            >
              No se encontraron códigos promocionales.
            </Typography>
          ) : (
            <>
              <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: theme_principal.palette.primary.light,
                      }}
                    >
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                        Código
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                        Porcentaje
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                        Estatus
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                        Fecha de Vencimiento
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                        Usos Máximos
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>
                        Veces Usado
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedPromoCodes.map((code) => (
                      <TableRow
                        key={code.id_codigo}
                        sx={{
                          "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
                        }}
                      >
                        <TableCell>
                          {highlightText(code.codigo, searchTerm)}
                        </TableCell>
                        <TableCell>
                          {highlightText(`${code.porcentaje}%`, searchTerm)}
                        </TableCell>
                        <TableCell>
                          {highlightText(
                            code.estatus === "A" ? "Activo" : "Inactivo",
                            searchTerm
                          )}
                        </TableCell>
                        <TableCell>
                          {highlightText(code.fecha_vencimiento, searchTerm)}
                        </TableCell>
                        <TableCell>
                          {highlightText(`${code.usos_maximos}`, searchTerm)}
                        </TableCell>
                        <TableCell>
                          {highlightText(`${code.veces_usado}`, searchTerm)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredPromoCodes.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default PromoCodeList;
