import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Heading } from "@aws-amplify/ui-react";

import UserInfo from "./user-info";
import Dashboard from "./dashboard";
import { fetchUserAttributes } from "aws-amplify/auth";
import axios from "axios";
import { signOut } from "aws-amplify/auth";
import { useUser } from "../userContext";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const { user, setUser } = useUser();
  const [suscripciones_data, setSuscripcionesData] = useState({});  // Aquí se corrige la declaración

  useEffect(() => {
    const fetchData = async () => {
      try {
        const attributes = await fetchUserAttributes();
        if (attributes.email) {
          const data = await getInfoUser(attributes.email);
          
          setUser(data.user);
          if (data.user.informativo) {
            setSuscripcionesData({
              activas: data.user.informativo.activas,
              demo: data.user.informativo.demo,
              suspendidas: data.user.informativo.suspendidas,
              canceladas: data.user.informativo.canceladas,
              expiradas: data.user.informativo.expiradas,
            });
          } else {
            setSuscripcionesData({ activas: 0, demo: 0, suspendidas: 0, canceladas: 0, expiradas: 0 });
          }
        }
      } catch (error) {
        console.error("Error al obtener datos del usuario:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setUser]);

  const getInfoUser = async (email) => {
    const url = process.env.REACT_APP_API_URL + "/usuario";
    const body = { idUser: email };

    try {
      const response = await axios.post(url, body, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });

      if (response.data.user.tipo === "C") {
        signOut();
      }

      return response.data;
    } catch (error) {
      console.error("Error al obtener datos del usuario:", error);
      throw error;
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {user ? (
        <Dashboard suscripciones={suscripciones_data} />
      ) : (
        <Heading level={1}>Bienvenido a Ventix</Heading>
      )}
    </>
  );
};

export default Home;
