import React, { useState } from "react";
import { Select, MenuItem, Checkbox, ListItemText, InputLabel, FormControl } from "@mui/material";

const MultiSelect = ({ label, options, selectedOptions, onChange }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl sx={{ minWidth: 120, mr: 2 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selectedOptions}  // Asegúrate de que selectedOptions sea un array
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}  // `selected` es un array
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={selectedOptions.indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelect;
